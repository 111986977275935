import { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';
import { IconCheck, IconClose } from '@belong/icons';
import { ButtonBase, Image, Text, Tooltip } from '@belong/ui';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import AddOrSubtract from 'components/AddOrSubtract/AddOrSubtract';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Satisfaction } from 'formcomponents/satisfaction/satisfaction';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getPricingAssignedEmployee } from '../utils';
import styles from './InitialListing.module.css';

const cx = classNames.bind(styles);

const InitialListing = ({ stepResource, stepConfigs, stepConfig, stepData, onSaveAndNext, onPrevious }) => {
  const assignedEmployee = getPricingAssignedEmployee(stepResource);

  const getInitialValues = () => {
    if (stepData) {
      return {
        ...stepData.initialPricing,
        userRequestedInitialRent: stepData.initialPricing?.initialListingRent,
      };
    }

    return {};
  };

  const [formValues, setFormValues] = useState(getInitialValues());

  useEffect(() => {
    setFormValues(getInitialValues());
  }, [stepData]);

  const isBadSentiment = (sentiment) => sentiment === 'Bad';

  const getAcceptedPriceFeedBack = () => {
    if (
      formValues?.userRequestedInitialRent !== stepResource.details.listing.initialListingRent ||
      isBadSentiment(formValues?.userInitialPricingSentiment)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = (values) => {
    const getUserRequestedInitialRent = () => {
      if (
        values.userRequestedInitialRent === stepResource.details.listing.initialListingRent &&
        !isBadSentiment(values.userInitialPricingSentiment)
      ) {
        return null;
      }
      return values.userRequestedInitialRent;
    };

    const updatedValues = {
      initialPricing: {
        ...values,
        userRequestedInitialRent: getUserRequestedInitialRent(),
        userInitialPricingSentiment: values.userInitialPricingSentiment,
        userFeedbackInitialPricing:
          isBadSentiment(values.userInitialPricingSentiment) ||
          values.userRequestedInitialRent > stepResource.details.listing.initialListingRent * 1.05
            ? values.userFeedbackInitialPricing
            : null,
        userAcceptedInitialPricing: getAcceptedPriceFeedBack(values),
      },
    };

    console.log('updatedValues:', updatedValues);
    onSaveAndNext(updatedValues);
  };

  return (
    <>
      <PostInspectionContentLayout
        showCityBackground
        stepConfigs={stepConfigs}
        stepConfig={stepConfig}
        title={POST_INSPECTION_FLOW_STRINGS['initial_listing.title']}
        employee={assignedEmployee}
        noOverlay
      >
        <div>
          <Form
            onSubmit={handleSubmit}
            initialValues={getInitialValues()}
            getForm={({ values }) => {
              const hasExceededSuggestedMaxInitialPrice =
                formValues.userRequestedInitialRent > stepResource.details.listing.initialListingRent;

              const hasExceededSuggestedMaxInitialPriceByFivePercent =
                formValues.userRequestedInitialRent >= stepResource.details.listing.initialListingRent * 1.05;
              return (
                <FormLayout>
                  <Confetti containerClassName="top-0 left-0 w-full fixed pointer-events-none" />

                  <div
                    className={cx('relative mt-2xl pt-2xl md:pt-xl pb-xl border border-solid rounded', {
                      ...(!hasExceededSuggestedMaxInitialPrice
                        ? { 'border-green': true, 'bg-green-translucent-light': true }
                        : { 'border-red': true, 'bg-red-translucent-light': true }),
                    })}
                  >
                    {!hasExceededSuggestedMaxInitialPrice ? (
                      <div className="absolute top-0 inset-x-0 object-fit">
                        <div className={cx('confetti_m_img')}>
                          <Image src="/pricing-flow/confetti_m.svg" alt="Confetti Background" />
                        </div>
                        <div className={cx('confetti_img')}>
                          <Image src="/pricing-flow/confetti_web.svg" alt="Confetti Background" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={cx('wind_img')}>
                          <Image src="/pricing-flow/wind_1.svg" alt="Wind Background" />
                        </div>
                        <div className={cx('wind_2_img')}>
                          <Image src="/pricing-flow/wind_2.svg" alt="Wind Background" />
                        </div>
                      </>
                    )}

                    <div className="absolute w-full top-0 inline-x-0 -translate-y-1/2">
                      <div
                        className={cx('rounded py-2xs px-xs mx-auto max-w-min md:max-w-max', {
                          'bg-green': !hasExceededSuggestedMaxInitialPrice,
                          'bg-red': hasExceededSuggestedMaxInitialPrice,
                        })}
                      >
                        <Text fontWeight="semibold" variant="p1" className="text-white text-center">
                          {
                            POST_INSPECTION_FLOW_STRINGS[
                              !hasExceededSuggestedMaxInitialPrice
                                ? 'initial_listing.qualified_gttl_tag'
                                : 'initial_listing.not_qualified_gttl_tag'
                            ]
                          }
                        </Text>
                      </div>
                    </div>

                    <Text fontWeight="semibold" className="text-center mb-xs">
                      {POST_INSPECTION_FLOW_STRINGS['initial_listing.initial_listing_price']}
                    </Text>

                    <Tooltip
                      contentProps={{ side: 'top', sideOffset: 0 }}
                      arrowProps={{ fill: 'fill-black' }}
                      content={
                        <div className="p-2sm rounded bg-black max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-left">
                          <Text className="text-white" variant="p1">
                            {
                              POST_INSPECTION_FLOW_STRINGS[
                                'initial_listing.increase_suggested_initial_listing_price_tooltip'
                              ]
                            }
                          </Text>
                        </div>
                      }
                      open={hasExceededSuggestedMaxInitialPriceByFivePercent}
                    >
                      <Text className={cx('initial-rent')} fontWeight="semibold">
                        <Field
                          size="large"
                          name="userRequestedInitialRent"
                          color={hasExceededSuggestedMaxInitialPrice ? 'text-red' : 'text-green'}
                          changeAmount={25}
                          min={Math.ceil(stepResource.details.listing.initialListingRent * 0.9)}
                          component={AddOrSubtract}
                          onChangeCustom={(value) => {
                            setFormValues({
                              ...formValues,
                              userRequestedInitialRent: value,
                            });
                          }}
                        />
                      </Text>
                    </Tooltip>

                    <div className="grid grid-cols-1 gap-sm md:grid-cols-2 mt-2xl mx-sm md:mx-2xl z-10">
                      {(!hasExceededSuggestedMaxInitialPrice
                        ? POST_INSPECTION_FLOW_STRINGS['initial_listing.benefits']
                        : POST_INSPECTION_FLOW_STRINGS['initial_listing.increase_suggested_initial_listing_price_cons']
                      ).map((item, index) => (
                        <div key={index} className="flex flex-row">
                          {!hasExceededSuggestedMaxInitialPrice ? (
                            <IconCheck height={16} width={20} className="text-green" />
                          ) : (
                            <IconClose height={16} width={20} className="text-red" />
                          )}
                          <Text fontWeight="semibold" className="flex flex-1 ml-xs">
                            {item}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>

                  {hasExceededSuggestedMaxInitialPriceByFivePercent && (
                    <>
                      <Text variant="h3" fontWeight="semibold" className="mt-xl">
                        {POST_INSPECTION_FLOW_STRINGS['initial_listing.why-question']}
                      </Text>
                      <Field placeholder="Note" component={InputFinalFormAdapter} name="userFeedbackInitialPricing" />
                    </>
                  )}

                  <div className={cx('align-center', 'hidden md:flex mt-xl')}>
                    <PostInspectionContentLayout.CtaButton type="submit">
                      {POST_INSPECTION_FLOW_STRINGS['initial_listing.next']}
                    </PostInspectionContentLayout.CtaButton>
                  </div>

                  <Space value={SPACE_TYPES.XXXL} />

                  <div className={cx('check-in-wrapper')}>
                    <Text fontSize="h3" fontWeight="semibold">
                      {POST_INSPECTION_FLOW_STRINGS['initial_listing.relationship']}
                    </Text>
                  </div>

                  <Satisfaction
                    onChangeCustom={(value) => {
                      setFormValues({
                        ...formValues,
                        userInitialPricingSentiment: value,
                      });
                      setTimeout(() => {
                        scroller.scrollTo('userFeedbackInitialPricing', {
                          duration: 1000,
                          smooth: true,
                        });
                      }, 150);
                    }}
                    fluid
                    validate={() => {}}
                    name="userInitialPricingSentiment"
                  />

                  {values.userInitialPricingSentiment === 'Bad' &&
                    !hasExceededSuggestedMaxInitialPriceByFivePercent && (
                      <>
                        <Space value={SPACE_TYPES.XXL} />
                        <TextBubbleWithUserProfile
                          text={POST_INSPECTION_FLOW_STRINGS['initial_listing.employee_reminder']}
                          employee={assignedEmployee}
                        />
                        <Space value={SPACE_TYPES.XL} />
                        <Field
                          placeholder={POST_INSPECTION_FLOW_STRINGS['initial_listing.tell-us-how-you-feel']}
                          component={InputFinalFormAdapter}
                          name="userFeedbackInitialPricing"
                        />
                      </>
                    )}

                  <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
                    <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
                      <Text fontWeight="semibold" className="text-white">
                        Previous
                      </Text>
                    </ButtonBase>

                    <ButtonBase type="submit" className="h-2xl flex-1 bg-green disabled:bg-gray">
                      <Text fontWeight="semibold" className="text-white">
                        Next
                      </Text>
                    </ButtonBase>
                  </div>
                </FormLayout>
              );
            }}
          />
        </div>
      </PostInspectionContentLayout>
    </>
  );
};

InitialListing.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepData: PropTypes.object.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};
export default InitialListing;
