// type Req = {
//   query: Record<string, string>;
//   [key: string]: any;
// };
// TODO: Move types from _middleware to here
var TEMPLATE_EXPERIMENT = {
  name: 'New Homeowner LP Test V2',
  salt: '03/25/2022-New Homeowner LP Test V2',
  createdOn: '03/25/2022',
  variations: [{
    value: 'control',
    weight: 0.5,
    description: 'Old LP'
  }, {
    value: 'test',
    weight: 0.5,
    description: 'New LP'
  }]
};
var NEW_HOMEOWNER_LP_TEST_V2 = {
  name: 'socia_proof_markets_lp_experiment',
  salt: '10/20/2023-socia_proof_markets_lp_experiment',
  createdOn: '10/20/2023',
  variations: [{
    value: 'control',
    weight: 0.5,
    description: 'Old LP'
  }, {
    value: 'test',
    weight: 0.5,
    description: 'New LP'
  }]
};
var RENT_ESTIMATE_FLOW_EXPERIMENT = {
  name: 'rent_estimate_flow_experiment',
  salt: '01/30/2024-rent_estimate_flow_experiment',
  createdOn: '01/30/2024',
  variations: [{
    value: 'control',
    weight: 0.5,
    description: 'Current calculator flow'
  }, {
    value: 'test',
    weight: 0.5,
    description: 'Rent estimate flow'
  }]
};
var GOOGLE_IDENTITY_SERVICES_EXPERIMENT = {
  name: 'google_identity_services_experiment',
  salt: '03/25/2024-google_identity_services_experiment',
  createdOn: '03/25/2024',
  variations: [{
    value: 'control',
    weight: 0,
    description: 'Google Third-Party Auth'
  }, {
    value: 'test',
    weight: 1,
    description: 'Google Identity Services'
  }]
};
var NEW_GUARANTEED_TIME_TO_LOVE_HOMEOWNERS_LANDING_PAGE_EXPERIMENT = {
  name: 'new_guaranteed_time_to_love_homeowners_landing_page_experiment',
  salt: '21/10/2024-new_guaranteed_time_to_love_homeowners_landing_page_experiment',
  createdOn: '21/10/2024',
  variations: [{
    value: 'control',
    weight: 0.7,
    description: 'Old Homeowners Landing Page'
  }, {
    value: 'test',
    weight: 0.3,
    description: 'New Homeowners Landing Page'
  }]
};
var ActiveExperiments = [RENT_ESTIMATE_FLOW_EXPERIMENT, GOOGLE_IDENTITY_SERVICES_EXPERIMENT, NEW_GUARANTEED_TIME_TO_LOVE_HOMEOWNERS_LANDING_PAGE_EXPERIMENT];

export { ActiveExperiments, GOOGLE_IDENTITY_SERVICES_EXPERIMENT, NEW_GUARANTEED_TIME_TO_LOVE_HOMEOWNERS_LANDING_PAGE_EXPERIMENT, NEW_HOMEOWNER_LP_TEST_V2, RENT_ESTIMATE_FLOW_EXPERIMENT, TEMPLATE_EXPERIMENT };
